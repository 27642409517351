import { useQueryClient } from '@tanstack/react-query';
import type { Address } from '@wagmi/core';
import { useState } from 'react';
import { useAsync } from 'react-use';
import { P, match } from 'ts-pattern';
import { useChainId, useContractRead } from 'wagmi';

import { registryABI } from '@endaoment-frontend/abis';
import { defaults } from '@endaoment-frontend/config';
import { getContractAddressForChain } from '@endaoment-frontend/multichain';
import type { DonationRecipient } from '@endaoment-frontend/types';
import { roundDown } from '@endaoment-frontend/utils';

import { getRecipient, type FullRecipient } from '../useRecipient';

type UseTransferFeeArgs = {
  sender?: Address | null;
  recipient: DonationRecipient;
  chainId: number;
  transferAmount: bigint;
  enabled?: boolean;
};

export const calculateFee = (feeBps: number, transferAmount: bigint): bigint => {
  const feePercent = feeBps / 10000;
  return BigInt(roundDown(feePercent * Number(transferAmount), 0));
};

const getReceiverAddressForTransferFee = ({ destination, chainId }: { destination?: FullRecipient; chainId: number }) =>
  match(destination)
    .with({ type: 'fund', entity: P.not(P.nullish) }, dest => dest.entity.v2ContractAddress)
    .with({ type: 'org', entity: P.not(P.nullish) }, dest => {
      const currentDeployment = dest.entity.deployments.find(d => d.chainId === chainId);
      if (!currentDeployment) return undefined;
      return currentDeployment.contractAddress;
    })
    .otherwise(() => undefined);

export const useTransferFee = ({
  sender,
  recipient,
  chainId,
  transferAmount,
  enabled: parentEnabled = true,
}: UseTransferFeeArgs) => {
  const queryClient = useQueryClient();
  const currentChainId = useChainId();
  const [receiverAddress, setReceiverAddress] = useState<Address>();
  useAsync(async () => {
    const newReceiverAddress = getReceiverAddressForTransferFee({
      destination: await getRecipient(recipient, queryClient),
      chainId,
    });
    setReceiverAddress(newReceiverAddress ?? undefined);
  }, [recipient, chainId]);
  const { data, isLoading } = useContractRead({
    abi: registryABI,
    address: getContractAddressForChain(currentChainId, 'registry'),
    args: [sender as Address, receiverAddress as Address],
    functionName: 'getTransferFee',
    enabled: parentEnabled && !!sender && !!receiverAddress,
    select: feeBps => calculateFee(feeBps, transferAmount),
  });

  if (!data) return { data: calculateFee(defaults.fees.transferBps, transferAmount), isLoading: false } as const;

  return { data, isLoading } as const;
};
