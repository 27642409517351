import type { QueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import { defaults } from '@endaoment-frontend/config';
import { RequestHandler } from '@endaoment-frontend/data-fetching';
import type { DonationRecipient, EVMToken, EntityType, OTCToken, SwapInfo, UUID } from '@endaoment-frontend/types';
import { arraySchemaInvalidsFiltered, evmTokenSchema, otcTokenSchema, swapInfoSchema } from '@endaoment-frontend/types';
import { equalAddress } from '@endaoment-frontend/utils';

import { GetOrg } from './orgs';

/**
 * Fetch a list of tokens available on a given chain
 */
export const GetEvmTokens = new RequestHandler(
  'GetEvmTokens',
  fetch => async (chainId: number, search?: string) => {
    const res = await fetch('/v2/tokens', {
      params: {
        chainId,
        tokenType: 'EvmToken',
      },
    });
    const evmTokens = z.object({ tokens: z.array(evmTokenSchema) }).parse(res).tokens;

    // TODO: Remove this once we have BE search
    if (!search) return evmTokens.slice(0, 20);

    return evmTokens
      .filter(token => {
        return (
          token.symbol.toLowerCase().includes(search.toLowerCase()) ||
          token.name.toLowerCase().includes(search.toLowerCase()) ||
          token.id.toString().toLowerCase().includes(search.toLowerCase()) ||
          token.contractAddress?.toLowerCase().includes(search.toLowerCase())
        );
      })
      .slice(0, 20);
  },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v2/tokens` }),
  },
);

export const GetEvmToken = new RequestHandler(
  'GetEvmToken',
  () =>
    async (queryClient: QueryClient, chainId: number, tokenId: number): Promise<EVMToken> => {
      const list = await GetEvmTokens.fetchFromQueryClient(queryClient, [chainId, tokenId.toString()]);
      const token = list.find(t => t.id === tokenId);
      if (!token) throw new Error(`Token with id ${tokenId} not found`);
      return token;
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v2/tokens` }),
    augmentArgs: ([, chainId, tokenId]) => [chainId, tokenId],
  },
);

export const GetOtcTokens = new RequestHandler(
  'GetOtcTokens',
  fetch =>
    async (search: string): Promise<Array<OTCToken>> => {
      const res = await fetch('/v2/tokens', {
        params: {
          tokenType: 'OtcToken',
        },
      });
      const otcTokens = z.object({ tokens: arraySchemaInvalidsFiltered(otcTokenSchema) }).parse(res).tokens;

      // TODO: Remove this once we have BE search
      if (!search) return otcTokens;

      return otcTokens.filter(
        otc =>
          // Search by name or symbol
          otc.name.toLowerCase().includes(search.toLowerCase()) ||
          otc.symbol.toLowerCase().includes(search.toLowerCase()) ||
          otc.otcAddress.toLowerCase().includes(search.toLowerCase()),
      );
    },
);

export const GetTokens = new RequestHandler(
  'GetTokens',
  fetch =>
    async (chainId: number, search?: string): Promise<Array<EVMToken | OTCToken>> => {
      const res = await fetch('/v2/tokens', {
        params: {
          tokenType: 'All',
          chainId,
        },
      });
      const tokens = z
        .object({ tokens: arraySchemaInvalidsFiltered(z.union([otcTokenSchema, evmTokenSchema])) })
        .parse(res).tokens;

      // TODO: Remove this once we have BE search
      if (!search) return tokens;

      return tokens.filter(t => {
        // Search by name, symbol, or id
        if (
          t.name.toLowerCase().includes(search.toLowerCase()) ||
          t.symbol.toLowerCase().includes(search.toLowerCase()) ||
          t.id.toString().toLowerCase().includes(search.toLowerCase())
        )
          return true;

        if (t.type === 'OtcToken') {
          return t.otcAddress.toLowerCase().includes(search.toLowerCase());
        }

        if (t.type === 'EvmToken') {
          return t.contractAddress?.toLowerCase().includes(search.toLowerCase());
        }

        return false;
      });
    },
);

export const GetToken = new RequestHandler(
  'GetToken',
  () =>
    async (queryClient: QueryClient, chainId: number, tokenId: number): Promise<EVMToken | OTCToken> => {
      const list = await GetTokens.fetchFromQueryClient(queryClient, [chainId]);
      const token = list.find(t => t.id === tokenId);
      if (!token) throw new Error(`Token with id ${tokenId} not found`);
      return token;
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v2/tokens` }),
    augmentArgs: ([, chainId, tokenId]) => [chainId, tokenId],
  },
);

type SwapInfoInput = {
  inputTokenId: number;
  /** A USDC value */
  amountIn: string;
  recipientEntityId: UUID;
  recipientEntityType: EntityType;
  chainId: number;
};
/**
 * Fetch swap information in order to assemble a donation transaction
 */
export const GetSwapInfo = new RequestHandler(
  'GetSwapInfo',
  fetch =>
    async (
      queryClient: QueryClient,
      amount: bigint,
      token: EVMToken,
      recipient: DonationRecipient,
      chainId: number,
    ): Promise<SwapInfo> => {
      // TODO: Figure out if we can use `multichain` lib instead
      const currentNetworkSettings = defaults.network.supportedNetworkSettings.find(n => n.chainId === chainId);
      if (!currentNetworkSettings) throw new Error(`No network settings found for chainId ${chainId}`);
      if (token.chainId !== chainId)
        throw new Error(`Token chainId ${token.chainId} does not match chainId ${chainId}`);

      // In the case of a stablecoin, we don't need to swap
      if (equalAddress(token.contractAddress, currentNetworkSettings.baseToken.contractAddress)) {
        return {
          amountIn: amount,
          swapWrapper: '0x',
          callData: '',
          quote: {
            expectedUsdc: amount,
            minimumTolerableUsdc: amount,
            priceImpact: 0,
            // wallet donation to fund has a 0.5% fee. wallet donation to org has a 1.5% fee.
            endaomentFee: (amount * (recipient.type === 'org' ? 15n : 5n)) / 1000n,
          },
          tokenIn: token.contractAddress,
          chainId,
        };
      }

      // Fetch swap info for orgs
      if (recipient.type === 'org') {
        const org = await GetOrg.fetchFromQueryClient(queryClient, [recipient.einOrId]);
        const res = await fetch('/v1/tokens/swap-info', {
          params: {
            amountIn: amount.toString(),
            inputTokenId: token.id,
            recipientEntityId: org.id,
            recipientEntityType: 'org',
            chainId,
          } satisfies SwapInfoInput,
        });
        return swapInfoSchema.parse(res);
      }

      // Fetch swap info for funds
      const res = await fetch('/v1/tokens/swap-info', {
        params: {
          amountIn: amount.toString(),
          inputTokenId: token.id,
          recipientEntityId: recipient.id,
          recipientEntityType: 'fund',
          chainId,
        } satisfies SwapInfoInput,
      });
      return swapInfoSchema.parse(res);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/tokens/swap-info`,
    }),
    augmentArgs: ([, amount, tokenId, recipient, chainId]) => [amount, tokenId, recipient, chainId],
  },
);

export const GetTokenPrice = new RequestHandler(
  'GetTokenPrice',
  fetch =>
    async (tokenId: number): Promise<number> => {
      const res = await fetch('/v1/tokens/price', {
        params: {
          id: tokenId,
        },
      });
      return z.number().parse(res);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v2/tokens/price`,
    }),
  },
);
