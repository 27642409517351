import { Box, Flex } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import Image from 'next/image';
import { match } from 'ts-pattern';
import { formatUnits } from 'viem';

import { GetToken } from '@endaoment-frontend/api';
import { defaults } from '@endaoment-frontend/config';
import type { DonationRecipient, PledgeOutcome, UUID } from '@endaoment-frontend/types';
import { AwaitingIcon, CheckmarkIcon, CloseIcon } from '@endaoment-frontend/ui/icons';
import { Button, Pill } from '@endaoment-frontend/ui/shared';
import { EntityCardWithLabel } from '@endaoment-frontend/ui/smart';
import { formatCurrency } from '@endaoment-frontend/utils';

import styles from '../DonationWizard.module.scss';

export const OtcDonationView = ({
  recipient,
  tokenId,
  tokenAmount,
  donationPledgeId,
  outcome,
  netDonation,
  protocolFees,
  onClose,
}: {
  recipient: DonationRecipient;
  tokenId: number;
  tokenAmount: bigint;
  donationPledgeId?: UUID;
  netDonation?: number;
  protocolFees?: number;
  outcome?: PledgeOutcome;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const { data: token } = GetToken.useQuery([queryClient, defaults.network.defaultChainId, tokenId]);

  return (
    <>
      <EntityCardWithLabel label='Donating to' entity={recipient} />

      <div className={styles['donation-info']}>
        <div>
          <h4>{outcome !== 'Success' && 'Expected'} Donation</h4>
          <h4>
            {!!token && (
              <div>
                {!!token.logoUrl && (
                  <Image src={token.logoUrl} alt={`${token.name} token icon`} width={20} height={20} />
                )}
                {`${formatUnits(tokenAmount, token.decimals)} ${token.symbol}`}
              </div>
            )}
          </h4>
        </div>
        {!!netDonation && (
          <div>
            <h4>Donation Value</h4>
            <h4>{formatCurrency(netDonation)}</h4>
          </div>
        )}
        {!!protocolFees && (
          <>
            <hr />
            <div>
              <h6>Endaoment Fee</h6>
              <h6>{formatCurrency(protocolFees)}</h6>
            </div>
          </>
        )}
      </div>

      <hr />
      {match(outcome)
        .with('Success', () => (
          <div className={styles.info}>
            <Pill variation='green' size='tiny'>
              <CheckmarkIcon strokeWidth={5} />
              Completed
            </Pill>
          </div>
        ))
        .with('Failure', () => (
          <div className={styles.info}>
            <Pill variation='red' size='tiny'>
              <CloseIcon />
              Transfer Failed
            </Pill>
            <Box mt='1rem'>
              Please{' '}
              <a href='mailto:help@endaoment.org' className={styles['mailto']}>
                contact our team
              </a>{' '}
              for more information.
            </Box>
          </div>
        ))
        .otherwise(() => (
          <>
            <Pill className={clsx(styles.disclaimer, styles.routing__disclaimer, styles['disclaimer--otc'])}>
              No action required. We're liquidating your donation and migrating the proceeds onchain. You will receive
              an email confirmation when complete, usually within one business day.
            </Pill>
            <div className={styles['awaiting']}>
              <AwaitingIcon />
              Awaiting Donation...
            </div>
            <hr />
            <div className={styles.info}>
              You can safely close this modal while the operation occurs. Check your email inbox for more details, or{' '}
              <a href='mailto:help@endaoment.org' className={styles['mailto']}>
                contact our team
              </a>
              .
            </div>
            <Flex flexDirection='row' className={styles['modal-actions']}>
              <Button onClick={onClose} size='medium' float={false}>
                Close
              </Button>
              <Button
                as='a'
                href={`mailto:admin@endaoment.org?subject=Endaoment: Crypto Donation Cancel Request - ID ${donationPledgeId}`}
                variation='red'
                size='medium'
                float={false}>
                Cancel Donation
              </Button>
            </Flex>
          </>
        ))}
    </>
  );
};
