import { Link } from '@chakra-ui/next-js';
import { Flex, Tooltip } from '@chakra-ui/react';
import clsx from 'clsx';
import Image from 'next/image';
import { useState } from 'react';
import { P, match } from 'ts-pattern';
import { formatUnits } from 'viem';

import { useAuthType } from '@endaoment-frontend/authentication';
import type { TransactionAsActivity } from '@endaoment-frontend/blockchain-interactions';
import { config } from '@endaoment-frontend/config';
import { getChainForChainId, getTransactionLink } from '@endaoment-frontend/multichain';
import { routes } from '@endaoment-frontend/routes';
import type {
  Activity,
  ActivitySubject,
  AllocationActivity,
  DonationActivity,
  DonationPledgeActivity,
  EntityLabel,
  TransferActivity,
} from '@endaoment-frontend/types';
import { addressSchema } from '@endaoment-frontend/types';
import {
  AwaitingIcon,
  CardIcon,
  ChainIcon,
  CheckmarkIcon,
  ErrorIcon,
  InTransitIcon,
  InfoIcon,
  PrivateActivityIcon,
  ReceiptIcon,
  RoutingIcon,
  StockIcon,
} from '@endaoment-frontend/ui/icons';
import { Button, Card, EmptyStateBlock, Loader } from '@endaoment-frontend/ui/shared';
import { capitalize, equalAddress, formatCurrency, formatUsdc } from '@endaoment-frontend/utils';

import { AddressPill, FundPill, OrgPill, PortfolioPill, SubprojectPill } from './ActivityPills';
import styles from './ActivitySection.module.scss';
import { ReceiptModal } from './ReceiptModal';

const VIEW_COUNT_INCREMENT = 5;

Tooltip.defaultProps = {
  placement: 'top',
};

export type UserActivity = Activity | TransactionAsActivity;

export const ActivitySection = ({
  activities,
  onGrantClick,
  subject,
  isLoading = false,
  className,
}: {
  activities?: Array<UserActivity>;
  onGrantClick?: () => void;
  subject?: ActivitySubject;
  isLoading?: boolean;
  className?: string;
}) => {
  const [activityViewCount, setActivityViewCount] = useState(VIEW_COUNT_INCREMENT);
  const [receiptActivity, setReceiptActivity] = useState<Activity>();

  const renderActivity = (activity: UserActivity) =>
    match(activity)
      .with({ type: 'donation' }, activity => <RecentDonation subject={subject} activity={activity} />)
      .with({ type: P.union('grant', 'internal_transfer') }, activity => (
        <RecentTransfer subject={subject} activity={activity} />
      ))
      .with({ type: 'portfolio_trade' }, activity => <RecentAllocation subject={subject} activity={activity} />)
      .with(
        {
          type: P.union(
            'fiat_donation_pledge',
            'migration_donation_pledge',
            'nec_donation_pledge',
            'stock_donation_pledge',
          ),
        },
        activity => <RecentDonationPledge subject={subject} activity={activity} />,
      )
      .with({ type: 'transaction' }, activity => <TransactionItem activity={activity} />)
      .exhaustive();

  const { isSocialAuth } = useAuthType();

  return (
    <>
      <ReceiptModal activity={receiptActivity} onClose={() => setReceiptActivity(undefined)} />
      {match({ activities, isLoading })
        .with({ activities: P.select(P.not(P.union(P.nullish, []))) }, activities => (
          <Card className={clsx(styles['container'], className)} mobileFullWidth>
            <div className={styles['title']}>
              <h2>Recent Activity</h2>
              {!!onGrantClick && (
                <Button variation='fund' size='small' filled gradient onClick={onGrantClick}>
                  Make a Grant
                </Button>
              )}
            </div>
            <ul>
              {activities.slice(0, activityViewCount).map(activity => (
                <li
                  key={activity.occurredAtUtc + activity.transactor}
                  className={styles['activity-item']}
                  data-testid='activity-list-item'>
                  <ActivityStatus activity={activity} subject={subject} />
                  {renderActivity(activity)}
                  <div>
                    {!!activity.automated && (
                      <Tooltip label='This activity was automatically generated by Endaoment'>
                        <span className={styles['automated-tooltip']}>
                          Automated
                          <InfoIcon color='currentColor' width='14px' />
                        </span>
                      </Tooltip>
                    )}
                    {(subject === 'fund' || subject === 'org') &&
                      isActivityPledge(activity) &&
                      (activity.outcome === 'AwaitingAssets' || activity.outcome === 'Pending') && (
                        <Tooltip label='This pledge is only viewable by you until the assets are received.'>
                          <span>
                            <PrivateActivityIcon />
                          </span>
                        </Tooltip>
                      )}
                    {(subject === 'user' ||
                      (subject !== 'featured' &&
                        isActivityPledge(activity) &&
                        (activity.outcome === 'AwaitingAssets' || activity.outcome === 'Pending'))) &&
                      activity.type !== 'transaction' &&
                      activity.type !== 'portfolio_trade' && (
                        <div onClick={() => setReceiptActivity(activity)} className={styles['receipt-button']}>
                          <Tooltip label='View receipt'>
                            <span>
                              <ReceiptIcon />
                            </span>
                          </Tooltip>
                        </div>
                      )}
                    {!!activity.chainId && !!activity.transactionHash && !isSocialAuth && (
                      <a
                        target='_blank'
                        href={getTransactionLink(activity.transactionHash, activity.chainId)}
                        rel='noreferrer'
                        className={styles['chain-link']}>
                        <Tooltip
                          label={`This activity occurred on ${getChainForChainId(activity.chainId)
                            ?.name}. Click to view in explorer.`}
                          placement='left'>
                          <span>
                            <ChainIcon chainId={activity.chainId} filled light />
                          </span>
                        </Tooltip>
                      </a>
                    )}
                  </div>
                </li>
              ))}
            </ul>
            <Flex alignItems='center' gap='1rem' justifyContent='center'>
              {activityViewCount < activities.length && (
                <Button
                  className={styles['see-more']}
                  filled
                  variation='purple'
                  size='small'
                  onClick={() => setActivityViewCount(prev => prev + VIEW_COUNT_INCREMENT)}>
                  See More
                </Button>
              )}
              {activityViewCount > VIEW_COUNT_INCREMENT && (
                <Button
                  className={styles['see-more']}
                  variation='purple'
                  size='small'
                  onClick={() => setActivityViewCount(VIEW_COUNT_INCREMENT)}>
                  See Less
                </Button>
              )}
            </Flex>
          </Card>
        ))
        .with({ activities: undefined, isLoading: true }, () => (
          <Card className={clsx(styles['container'], styles['container--no-data'], className)} mobileFullWidth>
            <Loader size='l' />
          </Card>
        ))
        .otherwise(() => (
          <Card className={clsx(styles['container'], styles['container--no-data'], className)} mobileFullWidth>
            <EmptyStateBlock
              variation='activity'
              title='No recent activity'
              description='Recent grant and donation activity will show up here.'
              spread>
              <Button as={Link} href={routes.app.explore()} variation='org' gradient filled size='medium'>
                Explore
              </Button>
            </EmptyStateBlock>
          </Card>
        ))}
    </>
  );
};

export const isActivityPledge = (activity: UserActivity): activity is DonationPledgeActivity =>
  activity.type === 'fiat_donation_pledge' ||
  activity.type === 'migration_donation_pledge' ||
  activity.type === 'nec_donation_pledge' ||
  activity.type === 'stock_donation_pledge';

const ActivityStatus = ({ activity, subject }: { activity: UserActivity; subject?: ActivitySubject }) => {
  return match({ activity, subject })
    .returnType<JSX.Element>()
    .with({ activity: { type: 'portfolio_trade', outcome: 'InTransit' } }, () => (
      <Tooltip label='This transaction is in transit, it might require 24-48 hours for processing.'>
        <em>
          <InTransitIcon className={clsx(styles['activity-status'], styles['activity-status--transit'])} />
        </em>
      </Tooltip>
    ))
    .with({ subject: 'user', activity: { type: 'transaction', status: 'pending' } }, () => (
      <Loader size='s' className={clsx(styles['activity-status'], styles['loading'])} />
    ))
    .with({ subject: 'user', activity: { type: 'transaction' } }, () => (
      <ErrorIcon className={clsx(styles['activity-status'], styles['error'])} />
    ))
    .with({ subject: 'user', activity: P.when(isActivityPledge) }, ({ activity }) =>
      match(activity.outcome)
        .returnType<JSX.Element>()
        .with('Failure', () => (
          <ErrorIcon width={18} height={18} className={clsx(styles['activity-status'], styles['error'])} />
        ))
        .with('Pending', () => <Loader size='s' className={clsx(styles['activity-status'], styles['loading'])} />)
        .with('Success', () => (
          <CheckmarkIcon
            className={clsx(styles['activity-status'], styles['success'])}
            strokeWidth={3}
            width={32}
            height={32}
          />
        ))
        .with('AwaitingAssets', () => (
          <Tooltip label='We are waiting to receive your assets, view receipt for more info'>
            <b className={clsx(styles['activity-status'], styles['awaiting'])}>
              <AwaitingIcon width={32} />
            </b>
          </Tooltip>
        ))
        .with('OnRamping', () => (
          <Tooltip label='We are in the process of putting funds onchain, view receipt for more info'>
            <b className={clsx(styles['activity-status'], styles['routing'])}>
              <RoutingIcon />
            </b>
          </Tooltip>
        ))
        .exhaustive(),
    )
    .with({ subject: 'user' }, () => (
      <CheckmarkIcon
        className={clsx(styles['activity-status'], styles['success'])}
        strokeWidth={3}
        width={32}
        height={32}
      />
    ))
    .with({ activity: P.when(isActivityPledge) }, ({ activity }) =>
      match(activity.outcome)
        .returnType<JSX.Element>()
        .with('OnRamping', () => (
          <Tooltip label='We are in the process of putting funds onchain'>
            <b className={clsx(styles['activity-status'], styles['routing'])}>
              <RoutingIcon />
            </b>
          </Tooltip>
        ))
        .with('AwaitingAssets', () => (
          <b className={clsx(styles['activity-status'], styles['awaiting'])}>
            <AwaitingIcon width={32} />
          </b>
        ))
        .with('Pending', () => <Loader size='s' className={clsx(styles['activity-status'], styles['loading'])} />)
        .otherwise(() => <></>),
    )
    .otherwise(() => <></>);
};

const TransactionItem = ({ activity }: { activity: TransactionAsActivity }) => (
  <span className={clsx(styles['donation-activity'], styles['donation-activity--transaction'])}>
    {activity.description}...
  </span>
);

export const RecentDonation = ({ activity, subject }: { activity: DonationActivity; subject?: ActivitySubject }) => {
  const { token, transactor, to, chainId, amount, usdcAmount } = activity;

  return (
    <span className={styles['donation-activity']}>
      {subject !== 'user' ? (
        <>
          <AddressPill transactor={transactor} chainId={chainId} />
          <em>donated</em>
        </>
      ) : (
        <>
          <em>Donated</em>
        </>
      )}
      {!!token && (
        <span>
          {`${formatUnits(amount, token.decimals)} ${token.symbol}`}
          <Image src={token.logoUrl} width={16} height={16} alt='' className={styles['logo-icon']} />
        </span>
      )}
      <b className={styles['money-pill']}>{formatCurrency(formatUsdc(usdcAmount))}</b>
      {(subject === 'featured' || subject === 'user') && (
        <>
          <span>to</span>
          {match(to)
            .with({ type: 'org' }, org => <OrgPill org={org} />)
            .with({ type: 'subproject' }, subproject => <SubprojectPill subproject={subproject} />)
            .with({ type: 'fund' }, fund => <FundPill id={fund.id} name={fund.name} />)
            .exhaustive()}
        </>
      )}
    </span>
  );
};

export const RecentDonationPledge = ({
  activity,
  subject,
}: {
  activity: DonationPledgeActivity;
  subject?: ActivitySubject;
}) => {
  const { transactor, to, chainId, type } = activity;
  const transactorIsNdao: boolean = config.endaoment.accounts.accountant.some(account =>
    equalAddress(account, transactor),
  );
  const shouldShowTransactor: boolean = !!transactor && !transactorIsNdao && !!chainId;

  const conjugation = match(activity.outcome)
    .returnType<string>()
    .with('Success', () => 'ed')
    .with('Failure', () => 'e')
    .otherwise(() => 'ing');
  const actionVerb = match({ activity, subject })
    .with({ activity: { type: 'migration_donation_pledge' } }, () => `migrat${conjugation}`)
    .with({ subject: P.not('user') }, () => `receiv${conjugation}`)
    .otherwise(() => `donat${conjugation}`);
  const action = activity.outcome === 'Failure' ? `failed to ${actionVerb}` : `${actionVerb}`;

  return (
    <span className={styles['donation-activity']}>
      {match({ activity, subject, shouldShowTransactor })
        .with(
          {
            subject: P.not('user'),
            shouldShowTransactor: true,
            activity: {
              transactor: P.not(P.nullish),
              chainId: P.not(P.nullish),
            },
          },
          ({ activity }) => (
            <>
              <AddressPill transactor={activity.transactor} chainId={activity.chainId} />
              <em>{action}</em>
            </>
          ),
        )
        .otherwise(() => (
          <em>{capitalize(action)}</em>
        ))}
      {type === 'nec_donation_pledge' && (
        <span>
          {`${formatUnits(activity.amount, activity.token.decimals)} ${activity.token.symbol}`}
          <Image src={activity.token.logoUrl} width={16} height={16} alt='' className={styles['logo-icon']} />
        </span>
      )}

      {/* TODO: Different activities are getting different logic for usdcAmount/net amounts/fee amounts - these should follow a standard - coordinate w/ BE  */}
      {!!activity.usdcAmount && (
        <b className={styles['money-pill']}>{formatCurrency(formatUsdc(activity.usdcAmount))}</b>
      )}

      {match(type)
        .with('fiat_donation_pledge', () => (
          <>
            {'via '}
            <b className={clsx(styles['donation-type'], styles['donation-type--cash'])}>
              <CardIcon />
              Cash Donation
            </b>
          </>
        ))
        .with('stock_donation_pledge', () => (
          <>
            {'via '}
            <b className={clsx(styles['donation-type'], styles['donation-type--stock'])}>
              <StockIcon />
              Brokerage
            </b>
          </>
        ))
        .otherwise(() => (
          <></>
        ))}
      {(subject === 'featured' || subject === 'user') && (
        <>
          <span>to</span>
          {match(to)
            .with({ type: 'org' }, org => <OrgPill org={org} />)
            .with({ type: 'subproject' }, subproject => <SubprojectPill subproject={subproject} />)
            .with({ type: 'fund' }, fund => <FundPill id={fund.id} name={fund.name} />)
            .exhaustive()}
        </>
      )}
    </span>
  );
};

const matchLabelPill = (entityLabel: EntityLabel) =>
  match(entityLabel)
    .with({ type: 'org' }, org => <OrgPill org={org} />)
    .with({ type: 'subproject' }, subproject => <SubprojectPill subproject={subproject} />)
    .with({ type: 'fund', name: P.when(v => addressSchema.safeParse(v).success) }, () => (
      <FundPill name='Private Fund' />
    ))
    .with({ type: 'fund' }, fund => <FundPill id={fund.id} name={fund.name} />)
    .otherwise(() => <></>);

export const RecentTransfer = ({ activity, subject }: { activity: TransferActivity; subject?: ActivitySubject }) => {
  // If we are not rendering Org activity and the sender isn't a private fund
  const showSender = subject !== 'org' && !addressSchema.safeParse(activity.from.name).success;

  const grantOrTransferText = activity.type === 'grant' ? 'grant' : 'transfer';

  if (showSender) {
    return (
      <span className={styles['grant-activity']}>
        {matchLabelPill(activity.from)}
        <em>{`${grantOrTransferText}ed`}</em>
        <b className={styles['money-pill']}>{formatCurrency(formatUsdc(activity.usdcAmount))}</b>
        <span>to</span>
        {matchLabelPill(activity.to)}
      </span>
    );
  }

  // If we are hiding the sender and we are showing org activity
  if (subject === 'org') {
    return (
      <span className={styles['grant-activity']}>
        <em>Received</em>
        <span>a</span>
        <b className={styles['money-pill']}>{formatCurrency(formatUsdc(activity.usdcAmount))}</b>
        <em>{grantOrTransferText}</em>
        from
        {matchLabelPill(activity.from)}
      </span>
    );
  }

  // If we are simply hiding the sender
  return (
    <span className={styles['grant-activity']}>
      {matchLabelPill(activity.to)}
      <em>received</em>
      <span>a</span>
      <b className={styles['money-pill']}>{formatCurrency(formatUsdc(activity.usdcAmount))}</b>
      <em>{grantOrTransferText}</em>
      from a
      <FundPill name='Private Fund' />
    </span>
  );
};

export const RecentAllocation = ({
  activity,
  subject,
}: {
  activity: AllocationActivity;
  subject?: ActivitySubject;
}) => {
  const isBuy = activity.tradeType === 'Buy';
  const action = isBuy ? `allocat${activity.outcome === 'InTransit' ? 'ing' : 'ed'}` : 'sold';
  return (
    <span className={styles['allocation-activity']}>
      {subject !== 'fund' ? (
        <>
          {matchLabelPill(activity.fund)}
          <em>{action}</em>
        </>
      ) : (
        <em>{action.charAt(0).toUpperCase() + action.slice(1)}</em>
      )}
      <b className={styles['money-pill']}>{formatCurrency(formatUsdc(activity.usdcAmount))}</b>
      &nbsp;{isBuy ? 'to' : 'from'}
      <PortfolioPill portfolio={activity.portfolio} />
    </span>
  );
};
