import type { QueryClient } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';

import { GetRecipient } from '@endaoment-frontend/api';
import type { DonationRecipient } from '@endaoment-frontend/types';

export const useRecipient = (recipient?: DonationRecipient) => {
  const queryClient = useQueryClient();
  return GetRecipient.useQuery([queryClient, recipient], {
    enabled: Boolean(recipient),
  });
};

export const getRecipient = async (recipient: DonationRecipient, queryClient: QueryClient) =>
  GetRecipient.fetchFromQueryClient(queryClient, [queryClient, recipient]);

export type FullRecipient = NonNullable<Awaited<ReturnType<typeof getRecipient>>>;
