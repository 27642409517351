import { type ModalProps } from '@chakra-ui/react';
import Image from 'next/image';
import { P, match } from 'ts-pattern';
import { formatUnits } from 'viem';

import { GetDonationPledge, GetFund, GetFundGrants, RegisterTrade } from '@endaoment-frontend/api';
import { defaults } from '@endaoment-frontend/config';
import {
  OtcDonationView,
  ViewBrokerageDonation,
  ViewCreditDonation,
  ViewGrant,
  useRecipient,
} from '@endaoment-frontend/donation-wizard';
import { MigrateFundView } from '@endaoment-frontend/fund-wizard';
import { getTransactionLink } from '@endaoment-frontend/multichain';
import { ViewAllocation } from '@endaoment-frontend/portfolio-wizard';
import type {
  Activity,
  Address,
  AllocationActivity,
  DonationActivity,
  DonationPledgeActivity,
  DonationRecipient,
  NecPledgeActivity,
  TransferActivity,
} from '@endaoment-frontend/types';
import { ShareIcon2 } from '@endaoment-frontend/ui/icons';
import { Button, StepModal } from '@endaoment-frontend/ui/shared';
import { EntityCardWithLabel } from '@endaoment-frontend/ui/smart';
import { convertUsdcToNumber, formatCurrency, formatDate, formatUsdc } from '@endaoment-frontend/utils';

import styles from './ReceiptModal.module.scss';

type ReceiptModalProps = {
  activity?: Activity;
};

const ACTIVITY_TYPE_LABEL_MAP: Record<Activity['type'], string> = {
  donation: 'Wallet Donation',
  grant: 'Grant',
  internal_transfer: 'Grant',
  stock_donation_pledge: 'Stock Donation',
  fiat_donation_pledge: 'Cash Donation',
  migration_donation_pledge: 'Migration',
  nec_donation_pledge: 'Crypto Donation',
  portfolio_trade: 'Portfolio Trade',
};

const destinationAsRecipient: (
  activity: DonationActivity | DonationPledgeActivity | TransferActivity,
) => DonationRecipient = activity =>
  activity.to.type === 'fund' ? { type: 'fund', id: activity.to.id } : { type: 'org', einOrId: activity.to.id };

const WalletDonationReceipt = ({ activity }: { activity: DonationActivity }) => {
  // TODO: replace with full details of wallet donation once backend supports
  const { data: destination } = useRecipient(destinationAsRecipient(activity));
  const { amount, usdcAmount, token, occurredAtUtc } = activity;
  return destination && token ? (
    <>
      <EntityCardWithLabel label='From' entity={{ type: 'user' }} />
      <EntityCardWithLabel label='Donating to' entity={destination} />
      <div className={styles['donation-info']}>
        <div>
          <h4>Donation</h4>
          <h4>
            <div>
              {`${formatUnits(amount, token.decimals)} ${token.symbol}`}
              <Image src={token.logoUrl} width={24} height={24} alt='' />
            </div>
          </h4>
        </div>
        <div>
          <h4>Proceeds</h4>
          <h4>{formatCurrency(formatUsdc(usdcAmount))}</h4>
        </div>
      </div>
      <hr />
      <div className={styles['transaction-status-section']}>
        <div>
          Donation completed:&nbsp;
          <span>{formatDate(occurredAtUtc, { includeTime: true, dateStyle: 'short' })}</span>
        </div>
        {!!activity.transactionHash && !!activity.chainId && (
          <Button
            as='a'
            href={getTransactionLink(activity.transactionHash, activity.chainId)}
            size='small'
            float={false}
            filled
            target='_blank'>
            View Transaction <ShareIcon2 color='#627EEA' />
          </Button>
        )}
      </div>
    </>
  ) : (
    <></>
  );
};

const GrantReceipt = ({ activity }: { activity: TransferActivity }) => {
  // TODO: implement get grant by id rather than from fund grants once BE implements
  const grantHash = activity.transactionHash;
  const fundId = activity.from.id;

  const { data: fundGrants } = GetFundGrants.useQuery([fundId], { enabled: !!grantHash });
  const grant = fundGrants?.find(grant => grant.transactionHash === grantHash);

  const { data: destination } = useRecipient(destinationAsRecipient(activity));

  return grant && destination && grantHash ? (
    <ViewGrant
      originFundId={fundId}
      destination={destination}
      grantStatus='success'
      grantAmount={grant.netAmount}
      grantInstructions={{
        specialInstructions: grant.specialInstructions ?? '',
        purpose: grant.purpose ?? '',
        recommender: grant.recommender ?? '',
      }}
      transactionChainId={activity.chainId}
      transactionHash={grantHash}
    />
  ) : (
    <></>
  );
};

const CashPledgeReceipt = ({ activity, onClose }: { activity: DonationPledgeActivity; onClose: () => void }) => {
  const { data: pledge } = GetDonationPledge.useQuery([activity.pledgeId]);
  const { data: destination } = useRecipient(destinationAsRecipient(activity));
  return pledge && destination && pledge.type === 'StripeDonationPledge' ? (
    <ViewCreditDonation
      id={activity.pledgeId}
      destination={destination}
      paymentType={pledge.selectedPaymentMethod ?? 'Other'}
      pledgedAmountCents={pledge.pledgedAmountCents}
      stripeFeesCents={pledge.stripeFeesCents}
      netDonation={convertUsdcToNumber(pledge.netDonationUsdc)}
      protocolFees={convertUsdcToNumber(pledge.protocolFeesUsdc)}
      outcome={pledge.outcome}
      onClose={onClose}
    />
  ) : (
    <></>
  );
};

const StockPledgeReceipt = ({ activity, onClose }: { activity: DonationPledgeActivity; onClose: () => void }) => {
  const { data: pledge } = GetDonationPledge.useQuery([activity.pledgeId]);
  const { data: destination } = useRecipient(destinationAsRecipient(activity));

  // TODO: have backend give us outcomes instead of coercing confirmation status on pledge
  const outcomeToStatus = match(pledge?.outcome)
    .with('Success', () => 'success')
    .with('Failure', () => 'error')
    .otherwise(() => 'loading');

  return pledge && destination && pledge.type === 'StockDonationPledge' ? (
    <ViewBrokerageDonation
      recipient={destination}
      //TODO: Make backend give us integer
      shares={Number(pledge.shares)}
      ticker={pledge.inputStock}
      status={outcomeToStatus as 'error' | 'loading' | 'success'}
      onClose={onClose}
    />
  ) : (
    <></>
  );
};

const CryptoPledgeReceipt = ({ activity, onClose }: { activity: NecPledgeActivity; onClose: () => void }) => {
  const { data: pledge } = GetDonationPledge.useQuery([activity.pledgeId]);
  const { data: destination } = useRecipient(destinationAsRecipient(activity));

  return pledge && destination && pledge.type === 'CryptoDonationPledge' ? (
    <OtcDonationView
      recipient={destination}
      tokenId={pledge.inputToken.id}
      // TODO: have backend give us the amount in pledge request, not in DTO at time of commit
      tokenAmount={activity.amount}
      donationPledgeId={activity.pledgeId}
      netDonation={convertUsdcToNumber(pledge.netDonationUsdc)}
      protocolFees={convertUsdcToNumber(pledge.protocolFeesUsdc)}
      outcome={pledge.outcome}
      onClose={onClose}
    />
  ) : (
    <></>
  );
};

const MigrationReceipt = ({ activity, onClose }: { activity: DonationPledgeActivity; onClose: () => void }) => {
  const { data: pledge } = GetDonationPledge.useQuery([activity.pledgeId]);
  const { data: fund } = GetFund.useQuery([activity.to.id]);

  // TODO: Get whole value from BE when it's available
  const amount = convertUsdcToNumber(
    ((activity.usdcAmount ?? 0n) * 10_000n) / (10_000n - BigInt(defaults.fees.donationBps)),
  );

  return pledge && fund && pledge.type === 'CashDonationPledge' ? (
    <MigrateFundView
      fund={fund}
      outcome={pledge.outcome}
      amount={amount}
      fundMigrationId={activity.pledgeId}
      onClose={onClose}
    />
  ) : (
    <></>
  );
};

const TradeReceipt = ({ activity }: { activity: AllocationActivity; onClose: () => void }) => {
  const { data: completedTrade } = RegisterTrade.useQuery([activity.transactionHash as Address, activity.chainId], {
    enabled: !!activity.transactionHash,
    // Needs strict refetch policy to ensure that the transaction is not re-registered (even though it should be idempotent)
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    networkMode: 'offlineFirst',
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  if (!completedTrade) return <></>;

  return (
    <ViewAllocation
      outcome={activity.outcome}
      allocationDetails={{
        entityId: activity.fund.id,
        portfolioId: activity.portfolio.id,
        tradeType: activity.tradeType,
        amount: activity.usdcAmount,
        // TODO: Implement once we store previous state information, currently unsupported
        // previousPosition: completedTrade.estimations.previousPositionUsdc,
        // newGrantableBalance: completedTrade.estimations.estimatedNewGrantableBalanceUsdc,
        // minNewGrantableBalance: completedTrade.estimations.minimumNewGrantableBalanceUsdc ?? undefined,
        // minNewEstimatedPosition: completedTrade.estimations.minimumNewEstimatedPositionUsdc ?? undefined,
        // newPosition: completedTrade.estimations.newEstimatedPositionUsdc,
        endaomentFee: completedTrade.fee ?? undefined,
        // priceImpact: completedTrade.tradeData.priceImpact ?? undefined,
      }}
    />
  );
};

const ReceiptModalInner = ({ activity, onClose }: { activity: Activity; onClose: () => void }) => {
  return match(activity)
    .with({ type: 'donation' }, activity => <WalletDonationReceipt activity={activity} />)
    .with({ type: P.union('grant', 'internal_transfer') }, activity => <GrantReceipt activity={activity} />)
    .with({ type: 'fiat_donation_pledge' }, activity => <CashPledgeReceipt onClose={onClose} activity={activity} />)
    .with({ type: 'stock_donation_pledge' }, activity => <StockPledgeReceipt onClose={onClose} activity={activity} />)
    .with({ type: 'nec_donation_pledge' }, activity => <CryptoPledgeReceipt onClose={onClose} activity={activity} />)
    .with({ type: 'migration_donation_pledge' }, activity => <MigrationReceipt onClose={onClose} activity={activity} />)
    .with({ type: 'portfolio_trade' }, activity => <TradeReceipt onClose={onClose} activity={activity} />)
    .exhaustive();
};

export const ReceiptModal = ({ activity, onClose }: Omit<ModalProps, 'children' | 'isOpen'> & ReceiptModalProps) => {
  return (
    <StepModal isOpen={!!activity} onClose={onClose}>
      <StepModal.StepsWrapper>
        {activity ? (
          <StepModal.Step header={ACTIVITY_TYPE_LABEL_MAP[activity.type]} onClose={onClose}>
            <div className={styles['receipt-modal']}>
              <ReceiptModalInner onClose={onClose} activity={activity} />
            </div>
          </StepModal.Step>
        ) : (
          <></>
        )}
      </StepModal.StepsWrapper>
    </StepModal>
  );
};
